import uberProfDevDocQuery from './graphql/table/uberadmin/uberProfDevDocQuery.graphql';
import profDevDocQuery from './graphql/survey/profDevDocQuery.graphql';
import groupingUnitQuery from './graphql/survey/groupingUnitQuery.graphql';
import goalsSurveyQuery from './graphql/survey/goalsSurveyQuery.graphql';
import networkSurveyQuery from './graphql/survey/networkSurveyQuery.graphql';
import uberTechnicalAssistance from './graphql/table/uberadmin/uberTechnicalAssistance.graphql';
import uberGoalsTableQuery from './graphql/table/uberadmin/uberGoalsTableQuery.graphql';
import uberNetworkMeetingsQuery from './graphql/table/uberadmin/uberNetworkMeetingsQuery.graphql';
import uberOrgInfo from './graphql/table/uberadmin/uberOrgInfo.graphql';
import uberAnnualEngagementQuery from './graphql/table/uberadmin/uberAnnualEngagementQuery.graphql';

export default {
    // PCANC Staff Members
    uberadmin: {
        questProjectList: [
            {
                label: 'Document Technical Assistance',
                deployments: [
                    {
                        label: 'Org & Staff Info',
                        handle: 'orgStaffInfo',
                        columns: [
                            {
                                handle: 'fullName',
                                label: 'PCANC Site Lead',
                                // Prod Variable
                                name: 'devTags.PCANCStaffMember',
                                sort: true,
                                renderAs: 'staffMember',
                            },
                            {
                                handle: 'lastUpdatedDate',
                                label: 'Last Visited Date',
                                name: 'lastVisitedDate',
                                sort: true,
                                filter: true,
                                filterSettings: { type: 'date' },
                                width: '10%',
                            },
                            {
                                handle: 'lastPerson',
                                label: 'Person(s) Last Saved',
                                name: 'lastUpdatedBy.app_variable_lastUpdatedBy',
                                renderAs: 'lastUpdatedByListOrgInfo',
                                width: '10%',
                                className: 'text-center',
                            },
                            {
                                handle: 'actions',
                                label: 'Action',
                                name: 'deployment.deploymentId',
                                className: 'text-center',
                                renderAs: 'editView',
                                hideInCsv: true,
                            },
                            {
                                handle: 'rowKey',
                                label: 'rowKey',
                                name: 'deployment.deploymentId',
                                hideInTable: true,
                                sort: true,
                                hideInCsv: true,
                            },
                        ],
                        query: uberOrgInfo,
                        gqlVariables: ['assignmentId', 'metricsPawn'],
                        orderBy: 'lastVisitedDate',
                        rowKey: 'deployment.deploymentId',
                        survey: {
                            pawnType: 'metricspawn',
                            flowProps: {
                                userLists: [
                                    'staffMemberList',
                                    'groupedOrgAdminList',
                                    'adminFacilitatorList',
                                    'otherOrgAdminList',
                                ],
                                variables: [
                                    'lastUpdatedBy',
                                    'organizationName',
                                    'staffMembers',
                                    'orgDSS',
                                    'orgDPH',
                                    'orgAddress',
                                    'orgZip',
                                    'orgCity',
                                    'orgState',
                                ],
                            },
                            gql: {
                                query: groupingUnitQuery,
                                variables: {
                                    groupingUnitId: 'groupingUnitId',
                                    deploymentHash: 'deploymentHash',
                                },
                            },
                        },
                    },
                    {
                        label: 'Annual Planning & Quarterly Progress',
                        handle: 'planningProgress',
                        columns: [
                            {
                                handle: 'fiscalYear',
                                label: 'Fiscal Year',
                                // Same on Prod/Local
                                name: 'devTags.FiscalYear',
                                sort: true,
                                filter: true,
                                width: '10%',
                            },
                            {
                                handle: 'staffMember',
                                label: 'PCANC Site Lead',
                                // Prod Var
                                name: 'devTags.ProgressPOC1',
                                renderAs: 'siteLead',
                                width: '10%',
                                className: 'text-center',
                            },
                            {
                                handle: 'lastUpdatedDate',
                                label: 'Date Last Visited',
                                name: 'lastVisitedDate',
                                sort: true,
                                filter: true,
                                filterSettings: { type: 'date' },
                                width: '10%',
                            },
                            {
                                handle: 'lastPerson',
                                label: 'Person(s) Last Saved',
                                name: 'lastUpdatedBy.app_variable_lastUpdatedBy',
                                renderAs: 'lastUpdatedByListAnnual',
                                width: '10%',
                                className: 'text-center',
                            },
                            {
                                handle: 'checkStatus',
                                label: 'Status',
                                // Prod Var
                                name: 'devTags.CompletionStatus',
                                sort: true,
                                renderAs: 'checkStatus',
                            },
                            {
                                handle: 'actions',
                                label: 'Action',
                                name: 'deployment.deploymentId',
                                className: 'text-center',
                                renderAs: 'annualActions',
                                width: '20%',
                                hideInCsv: true,
                            },
                            {
                                handle: 'rowKey',
                                label: 'rowKey',
                                name: 'deployment.deploymentId',
                                hideInTable: true,
                                sort: true,
                                hideInCsv: true,
                            },
                        ],
                        query: uberAnnualEngagementQuery,
                        gqlVariables: ['assignmentId', 'metricsPawn'],
                        orderBy: 'lastVisitedDate',
                        isAscentOrder: false,
                        rowKey: 'deployment.deploymentId',
                        survey: {
                            pawnType: 'metricspawn',
                            flowProps: {
                                userLists: [
                                    'staffMemberList',
                                    'groupedOrgAdminList',
                                    'groupedAdminFacilitatorList',
                                    'otherOrgAdminList',
                                ],
                                variables: ['lastUpdatedBy', 'organizationName', 'staffMembers', 'adminFacilitators'],
                            },
                            gql: {
                                query: groupingUnitQuery,
                                variables: {
                                    groupingUnitId: 'groupingUnitId',
                                    deploymentHash: 'deploymentHash',
                                },
                            },
                        },
                    },
                    {
                        // Multi org select
                        label: 'Professional/Workforce Development',
                        handle: 'profDevDoc',
                        columns: [
                            {
                                handle: 'sessionDate',
                                label: 'Date of Session',
                                // Same var on prod and local
                                name: 'devTags.HeldDate',
                                sort: true,
                                filter: true,
                                filterSettings: { type: 'date' },
                                width: '10%',
                            },
                            {
                                handle: 'sessionType',
                                label: 'Session Type',
                                // Same var on prod and local
                                name: 'devTags.SessionType',
                                sort: true,
                                filter: true,
                            },
                            {
                                handle: 'trainings',
                                label: 'Session Title',
                                // Same vars on prod and local
                                name: 'devTags.SessionTitle',
                                renderAs: 'trainings',
                                hideInCsv: true,
                            },
                            {
                                handle: 'trainings',
                                label: 'Session Title',
                                // Same vars on prod and local
                                name: 'devTags.SessionTitle',
                                renderAs: 'trainingsCsv',
                                hideInTable: true,
                            },
                            {
                                handle: 'attendees',
                                label: '# of Attendees',
                                name: 'attendees',
                                renderAs: 'attendees',
                                className: 'text-center',
                                width: '10%',
                                hideInCsv: true,
                            },
                            {
                                handle: 'selectedOrgs',
                                label: '# of Organizations Represented',
                                name: 'selectedOrgs',
                                renderAs: 'selectedOrgs',
                                className: 'text-center',
                                width: '10%',
                                hideInCsv: true,
                            },
                            {
                                handle: 'attendeesCsv',
                                label: '# of Attendees',
                                name: 'attendeesCsv',
                                renderAs: 'attendeesCsv',
                                className: 'text-center',
                                hideInTable: true,
                            },
                            {
                                handle: 'selectedOrgsCsv',
                                label: '# of Organizations Represented',
                                name: 'selectedOrgsCsv',
                                renderAs: 'selectedOrgsCsv',
                                className: 'text-center',
                                hideInTable: true,
                            },
                            {
                                handle: 'actions',
                                label: 'Action',
                                name: 'deployment.deploymentId',
                                className: 'text-center',
                                renderAs: 'profDevActions',
                                hideInCsv: true,
                            },
                        ],
                        query: uberProfDevDocQuery,
                        gqlVariables: ['assignmentId', 'projectId'],
                        orderBy: 'deployment.deploymentId',
                        rowKey: 'deployment.deploymentId',
                        survey: {
                            pawnType: 'actingPawn',
                            flowProps: {
                                userLists: ['staffMemberList', 'orgAdminList', 'adminFacilitatorList', 'orgList'],
                                variables: ['orgSelection', 'orgAdmins', 'staffMembers', 'adminFacilitators'],
                            },

                            gql: {
                                query: profDevDocQuery,
                                variables: {
                                    groupingUnitId: 'orgList',
                                    deploymentHash: 'deploymentHash',
                                },
                            },
                        },
                    },
                    {
                        label: 'TA: Session',
                        handle: 'technicalAssistance',
                        columns: [
                            {
                                handle: 'goalDate',
                                label: 'Goal Date',
                                name: 'devTags.GoalDate',
                                filter: true,
                                sort: true,
                                width: '10%',
                            },
                            {
                                handle: 'staffAttended',
                                label: 'PCANC Staff Present',
                                name: 'devTags.StaffGoalsAttended',
                                renderAs: 'goalAttendeesStaff',
                                hideInCsv: true,
                                className: 'text-center',
                                width: '10%',
                            },
                            {
                                handle: 'staffAttendedCsv',
                                label: 'PCANC Staff Present',
                                name: 'devTags.StaffGoalsAttended',
                                renderAs: 'goalAttendeesStaffCsv',
                                hideInTable: true,
                            },
                            {
                                handle: 'orgStaff',
                                label: 'Organization Staff Present',
                                name: 'devTags.AdminFacAttended',
                                renderAs: 'goalAttendeesAdminFac',
                                hideInCsv: true,
                                className: 'text-center',
                                width: '10%',
                            },
                            {
                                handle: 'orgStaffCsv',
                                label: 'Organization Staff Present',
                                name: 'devTags.AdminFacAttended',
                                renderAs: 'goalAttendeesAdminFacCsv',
                                hideInTable: true,
                            },
                            {
                                handle: 'purpose',
                                label: 'Purpose',
                                name: 'devTags.GoalPurpose',
                                sort: true,
                                filter: true,
                            },
                            {
                                handle: 'followUp',
                                label: 'Follow-up Requested',
                                name: 'devTags.FollowUp',
                                renderAs: 'followUp',
                                className: 'text-center',
                                width: '10%',
                                sort: true,
                                hideInCsv: true,
                            },
                            {
                                handle: 'followUpCsv',
                                label: 'Follow-up Requested',
                                name: 'devTags.FollowUp',
                                renderAs: 'followUpCsv',
                                hideInTable: true,
                            },
                            {
                                handle: 'followUpDate',
                                label: 'Follow-up Date',
                                name: 'devTags.FollowUpDate',
                                width: '10%',
                                className: 'text-center',
                                sort: true,
                                filter: true,
                                filterSettings: { type: 'date' },
                            },
                            {
                                handle: 'actions',
                                label: 'Action',
                                name: 'deployment.deploymentId',
                                className: 'text-center',
                                renderAs: 'editView',
                                width: '15%',
                                hideInCsv: true,
                            },
                        ],
                        query: uberTechnicalAssistance,
                        gqlVariables: ['assignmentId', 'metricsPawn'],
                        orderBy: 'deployment.deploymentId',
                        rowKey: 'deployment.deploymentId',
                        survey: {
                            pawnType: 'metricspawn',
                            flowProps: {
                                userLists: [],
                                variables: [
                                    'goals',
                                    'orgAdmins',
                                    'adminFacilitators',
                                    'staffMembers',
                                    'lastUpdatedBy',
                                    'organizationName',
                                    'organizationId',
                                ],
                            },
                            gql: {
                                query: goalsSurveyQuery,
                                variables: {
                                    assignmentId: 'assignmentId',
                                    groupingUnitId: 'groupingUnitId',
                                    deploymentHash: 'deploymentHash',
                                    metricsPawn: 'metricsPawn',
                                },
                            },
                        },
                    },
                    {
                        label: 'TA: Goals',
                        handle: 'goalsTA',
                        columns: [
                            {
                                handle: 'goalDate',
                                label: 'Goal Date',
                                name: 'devTags.GoalDate',
                                filter: true,
                                sort: true,
                                width: '10%',
                            },
                            {
                                handle: 'goalType',
                                label: 'Goal Type',
                                name: 'devTags.GoalType',
                                filter: true,
                                sort: true,
                                className: 'text-center',
                                width: '10%',
                            },
                            {
                                handle: 'orgStaff',
                                label: 'Organization Staff Name',
                                name: 'devTags.GoalPerson',
                                filter: true,
                                sort: true,
                                renderAs: 'nameOnly',
                                width: '20%',
                            },
                            {
                                handle: 'goalRelatedTo',
                                label: 'Goal Topic',
                                name: 'devTags.GoalRelatedTo',
                                filter: true,
                                sort: true,
                                width: '15%',
                            },
                            {
                                handle: 'documentation',
                                label: 'Goal Statement',
                                name: 'devTags.DocumentationText',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'status',
                                label: 'Goal Status',
                                name: 'devTags.GoalProgress',
                                filter: true,
                                sort: true,
                                renderAs: 'statusTag',
                                width: '15%',
                            },
                            {
                                handle: 'actions',
                                label: 'Actions',
                                name: 'deployment.deploymentId',
                                className: 'text-center',
                                renderAs: 'editViewGoals',
                                width: '15%',
                                hideInCsv: true,
                            },
                        ],
                        query: uberGoalsTableQuery,
                        gqlVariables: ['assignmentId', 'metricsPawn', 'selectedPawn'],
                        orderBy: 'deployment.deploymentId',
                        rowKey: 'deployment.deploymentId',
                        survey: {
                            pawnType: 'metricspawn',
                            flowProps: {
                                userLists: [],
                                variables: [
                                    'lastUpdatedBy',
                                    'organizationName',
                                    'organizationId',
                                    'orgAdmins',
                                    'adminFacilitators',
                                ],
                            },
                            gql: {
                                query: goalsSurveyQuery,
                                variables: {
                                    assignmentId: 'assignmentId',
                                    groupingUnitId: 'groupingUnitId',
                                    deploymentHash: 'deploymentHash',
                                    metricsPawn: 'metricsPawn',
                                },
                            },
                        },
                    },
                    {
                        label: 'Network and Coalition Meetings',
                        handle: 'networkMeetings',
                        columns: [
                            {
                                handle: 'meetingDate',
                                label: 'Date of Meeting',
                                name: 'devTags.HeldDate',
                                filter: true,
                                sort: true,
                                filterSettings: { type: 'date' },
                                width: '15%',
                            },
                            {
                                handle: 'lastUpdatedBy',
                                label: 'Documenting Staff Member',
                                name: 'lastUpdatedBy.app_variable_lastUpdatedBy',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'meetingType',
                                label: 'Type of Meeting',
                                name: 'devTags.MeetingType',
                                renderAs: 'meetingType',
                            },
                            {
                                handle: 'actions',
                                label: 'Actions',
                                name: 'deployment.deploymentId',
                                className: 'text-center',
                                renderAs: 'editViewNetwork',
                                width: '10%',
                                hideInCsv: true,
                            },
                        ],
                        query: uberNetworkMeetingsQuery,
                        gqlVariables: ['assignmentId', 'actingPawn'],
                        orderBy: 'deployment.deploymentId',
                        rowKey: 'deployment.deploymentId',
                        survey: {
                            pawnType: 'actingPawn',
                            flowProps: {
                                userLists: ['staffMemberList', 'adminFacilitatorList'],
                                variables: [
                                    'lastUpdatedBy',
                                    'orgSelection',
                                    'adminFacilitators',
                                    'orgAdmins',
                                    'staffMembers',
                                ],
                            },
                            gql: {
                                query: networkSurveyQuery,
                                variables: {
                                    groupingUnitId: 'orgList',
                                    deploymentHash: 'deploymentHash',
                                    actingPawn: 'actingPawn',
                                },
                            },
                        },
                    },
                    {
                        label: 'Referral to Information & Resources',
                        handle: 'infoResources',
                        isSurveyOnly: true,
                        survey: {
                            pawnType: 'actingPawn',
                            flowProps: {},
                            gql: {},
                        },
                    },
                ],
            },
            {
                label: 'TA Feedback and Outcomes',
                deployments: [
                    {
                        // Deployment button
                        label: 'Technical Assistance',
                        handle: 'profDevFO',
                        columns: [
                            {
                                handle: 'name',
                                label: 'Name',
                                name: 'label',
                                className: 'text-center',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'openDate',
                                label: 'Open Date',
                                name: 'opensAt',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'closeDate',
                                label: 'Close Date',
                                name: 'closesAt',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'status',
                                label: 'Status',
                                name: 'isOpen',
                                filter: true,
                                sort: true,
                                renderAs: 'statusTag',
                            },
                            {
                                handle: 'responses',
                                label: 'Completed At',
                                name: 'completedResponses',
                                sort: true,
                                filter: true,
                            },
                        ],
                        gqlVariables: [],
                        orderBy: '',
                        rowKey: '',
                    },
                    {
                        label: 'Biannual Feedback',
                        handle: 'biannualFeedback',
                        columns: [
                            {
                                handle: 'visitedDate',
                                label: 'Site Visit Date',
                                name: 'lastVisitedDate',
                                filter: true,
                                sort: true,
                                filterSettings: { type: 'date' },
                            },
                            {
                                handle: 'fullName',
                                label: 'Name',
                                name: 'lastUpdatedBy',
                                sort: true,
                                renderAs: 'lastUpdatedByName',
                            },
                            {
                                handle: 'email',
                                label: 'Email',
                                name: 'lastUpdatedByEmail',
                                sort: true,
                                renderAs: 'lastUpdatedByEmail',
                            },
                            {
                                handle: 'status',
                                label: 'Status',
                                name: 'status',
                                filter: true,
                                sort: true,
                                renderAs: 'statusTag',
                            },
                            {
                                handle: 'completedAt',
                                label: 'Completed At',
                                name: 'completedDate',
                                filter: true,
                                sort: true,
                                filterSettings: { type: 'date' },
                            },
                            {
                                handle: 'actions',
                                label: 'Action',
                                name: 'deployment.deploymentId',
                                renderAs: 'editView',
                            },
                        ],
                        gqlVariables: [],
                        orderBy: '',
                        rowKey: '',
                    },
                ],
            },
        ],
    },
    // "Org Admins"
    guAdmin: {
        questProjectList: [
            {
                label: 'TA Feedback and Outcomes',
                deployments: [
                    {
                        label: 'Professional Development',
                        handle: 'profDevFO',
                        gqlVariables: [],
                        orderBy: '',
                        rowKey: '',
                    },
                ],
            },
        ],
    },
    // Facilitators
    instructor: {
        questProjectList: [
            {
                label: 'Org Participant Outcomes',
                deployments: [
                    {
                        label: 'Program Facilitator Form',
                        handle: 'facilitatorForm',
                        columns: [
                            {
                                handle: 'sessionId',
                                label: 'Session Id',
                                name: 'deploymentId',
                                className: 'text-center',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'organization',
                                label: 'TA Org',
                                name: 'settings.orgName',
                                className: 'text-center',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'program',
                                label: 'Program(s)',
                                name: 'label',
                                className: 'text-center',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'openDate',
                                label: 'Open Date',
                                name: 'opensAt',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'closeDate',
                                label: 'Close Date',
                                name: 'closesAt',
                                filter: true,
                                sort: true,
                            },
                            {
                                handle: 'status',
                                label: 'Status',
                                name: 'isOpen',
                                filter: true,
                                sort: true,
                                renderAs: 'statusTag',
                            },
                            {
                                handle: 'responses',
                                label: 'Completed At',
                                name: 'completedResponses',
                                sort: true,
                                filter: true,
                            },
                            {
                                handle: 'actions',
                                label: 'Action',
                                name: 'hash',
                                renderAs: 'editDeployment',
                            },
                        ],
                        gqlVariables: [],
                        orderBy: '',
                        rowKey: '',
                    },
                ],
            },
        ],
    },
};
